import {
  FaFacebookF,
  FaInstagram,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import GrayBackground from "../GrayBackground";

function Socials() {
  return (
    <GrayBackground title="Socials">
      <div className="d-flex justify-content-evenly mb-3">
        <a
          href="https://www.youtube.com/user/KVRXAustin"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaYoutube style={{ fontSize: 35 }} />
        </a>
        <a
          href="https://open.spotify.com/user/x5baixg8dkm24do73zkfisyyx"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaSpotify style={{ fontSize: 35 }} />
        </a>
        <a
          href="https://twitter.com/KVRX"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaTwitter style={{ fontSize: 35 }} />
        </a>
      </div>
      <div className="d-flex justify-content-evenly px-5">
        <a
          href="https://www.facebook.com/kvrxaustin"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaFacebookF style={{ fontSize: 35 }} />
        </a>
        <a
          href="https://www.instagram.com/kvrxaustin"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaInstagram style={{ fontSize: 35 }} />
        </a>
      </div>
    </GrayBackground>
  );
}

export default Socials;
