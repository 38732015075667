import styles from "./GrayBackground.module.css";

function GrayBackground({ children, title }) {
  return (
    <div className={`${styles.container} shadow`}>
      <div className={styles.title}><h2>{title}</h2></div>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
}

export default GrayBackground;
