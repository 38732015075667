import dynamic from "next/dynamic";
import Link from "next/link";
import { gql } from "@apollo/client";
import useInView from "react-cool-inview";

import GrayBackground from "@/components/home/GrayBackground";
import Socials from "@/components/home/Socials";
import YouTube from "@/components/home/YouTube";
import Player from "@/components/home/Player";
import Seo from "@/components/seo";
import AdBar from "@/components/Ads/AdBar";
import { fetchYouTube } from "./api/youtube";

import styles from "@/styles/Home.module.css";
import handleStaticProps from "src/lib/handle-static-props";

const AlbumReviewGrid = dynamic(() =>
  import("@/components/common/AlbumReviewGrid")
);
const BlogPost = dynamic(() => import("@/components/home/BlogPost"));
const Topless39List = dynamic(() => import("@/components/home/Topless39List"));

export async function getStaticProps() {
  // Fetch YouTube
  const youtube = fetchYouTube();

  const { data } = await handleStaticProps({
    pageQuery,
  });

  return {
    props: { data, youtube: await youtube },
    revalidate: 60,
  };
}

function Home({ data, youtube }) {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  });
  const { blogPosts, events, reviews, topless39 } = {
    blogPosts: data.allBlogPosts.edges,
    events: data.allEvents,
    reviews: data.allReviews.edges,
    topless39: data.topChart.albums,
  };

  return (
    <div className={`container ${styles.container}`}>
      <Seo />
      {/* ROW 1 */}
      {/* INTRO & NOW PLAYING */}
      <div className="row">
        <div className="col-md-8 py-3">
          <div className={styles.header}>
            <h1 className="text-end">
              <span>None of the hits,</span>
              <br />
              <span>all of the time</span>
            </h1>
          </div>
        </div>
        <div className="col-md-4 py-3">
          <GrayBackground title="Now Playing">
            <Player />
          </GrayBackground>
        </div>
      </div>
      {/* ROW 2 */}
      {/* SOCIALS & YOUTUBE */}
      <div className="row">
        <div className="col-md-4 py-3">
          <Socials />
        </div>
        <YouTube {...youtube} />
      </div>
      {/* ROW 3 */}
      {/* OTHER */}
      <AdBar />
      <div ref={observe}>
        {inView && (
          <>
            {/* ROW 5 */}
            {/* BLOG */}
            <div className="row">
              <div className="col-md-3 py-3">
                <p className="text-muted">
                  <small>BLOG</small>
                </p>
                <h1>Recent Features</h1>
              </div>
              <div className="col-md-9 py-3">
                <div className="row">
                  {blogPosts.map((post) => (
                    <BlogPost post={post} key={`blogpost-${post.id}`} />
                  ))}
                </div>
                <div className="text-end">
                  <Link href="/blog/">
                    <a className={styles.link}>MORE POSTS &gt;</a>
                  </Link>
                </div>
              </div>
            </div>
            {/* Row 6 */}
            {/* REVIEWS */}
            <div className="row">
              <div className="col-md-3 py-3">
                <p className="text-muted">
                  <small>REVIEWS</small>
                </p>
                <h1>New Reviews</h1>
              </div>
              <div className="col-md-9 py-3">
                <AlbumReviewGrid reviews={reviews} />
                <div className="text-end">
                  <Link href="/reviews/">
                    <a className={styles.link}>MORE REVIEWS &gt;</a>
                  </Link>
                </div>
              </div>
            </div>
            {/* ROW 7 */}
            {/* TOPLESS 39 */}
            <div className="row">
              <div className="col-md-3 py-3">
                <p className="text-muted">
                  <small>CHARTS</small>
                </p>
                <h1>Topless 39</h1>
              </div>
              <div className="col-md-9 py-3">
                <Topless39List topless39={topless39} />
                <div className="text-end">
                  <Link href="/topless39/">
                    <a className={styles.link}>
                      <span className="d-none d-lg-inline">34</span>
                      <span className="d-none d-sm-inline d-lg-none">35</span>
                      <span className="d-sm-none">36</span> MORE &gt;
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            {/* ROW 8 */}
            {/* INSTAGRAM */}
            {/* <div>
              <div className="col-md-3 py-3">
                <p className="text-muted">
                  <small>INSTAGRAM</small>
                </p>
                <h1>Follow Us</h1>
              </Col>
              <div className="col-md-9 py-3"></Col>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;

const pageQuery = gql`
  query HomePage {
    allBlogPosts(first: 2) {
      edges: objects {
        __typename
        id
        title
        date
        slug
        preview
        image {
          url
          width
          height
        }
        url
      }
    }
    allReviews(first: 4) {
      edges: objects {
        __typename
        id
        cover
        artist
        title
        url
      }
    }
    topChart {
      albums(first: 5) {
        __typename
        id
        cover
        artist
        title
        url
      }
    }
  }
`;
