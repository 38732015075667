import { Col } from "react-bootstrap";

import Image from "@/components/Image";

import styles from "@/styles/Home.module.css";

const YouTube = ({ thumbnail, title, url }) => {
  return (
    <>
      <Col md={4} className="py-3">
        <a href={url} rel="noopener noreferrer" target="_blank" title={title}>
          <div className={styles.youtube_img}>
            {thumbnail.url ? (
              <Image
                src={thumbnail.url}
                width={thumbnail.width}
                height={thumbnail.height}
                layout="responsive"
                alt={title}
                priority={true}
              />
            ) : null}
          </div>
        </a>
      </Col>
      <Col md={4} className="py-3">
        <span className={styles.watch}>WATCH</span>
        <h1>
          <a href={url} rel="noopener noreferrer" target="_blank">
            {title}
          </a>
        </h1>

        <p className="float-end">
          <a
            href="https://www.youtube.com/user/KVRXAustin"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
          >
            MORE ON
            <br />
            YOUTUBE &gt;
          </a>
        </p>
      </Col>
    </>
  );
};

export default YouTube;
