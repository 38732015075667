import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { useRouter } from "next/router";

import config from "../config";

const Seo = ({ title, description, image, article }) => {
  const { asPath, isReady } = useRouter();
  const [path, setPath] = useState();

  useEffect(() => {
    if (isReady)
      setPath(asPath);
  }, [isReady, asPath]);

  const seo = {
    title: title || config.title,
    titleTemplate: title ? config.titleTemplate : `%s | ${config.description}`,
    description: description || config.description,
    image: `${config.siteUrl}${
      (image && image.replace(config.siteUrl, "")) || config.image
    }`,
    url: `${config.siteUrl}${path}`,
  };

  return (
    <Head>
      {/* MAIN */}
      <title>{seo.titleTemplate.replace("%s", seo.title)}</title>
      <meta name="description" content={seo.description} />
      <link rel="icon" href="/favicon.ico" />
      <meta name="image" content={seo.image} />
      {/* FACEBOOK */}
      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}
      {/* TWITTER */}
      <meta name="twitter:card" content="summary_large_image" />

      {config.social.twitter && (
        <meta name="twitter:creator" content={config.social.twitter} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Head>
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
