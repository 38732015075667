import { Spinner } from "react-bootstrap";
import {
  MdPlayArrow as PlayArrowIcon,
  MdPause as PauseIcon,
} from "react-icons/md";

import { useNowPlaying } from "@/components/contexts/now-playing";
import { usePlayerState } from "@/components/contexts/player";

const Player = () => {
  const { isPlaying, isLoading, toggleLivestream } = usePlayerState();
  const { song: currentSong } = useNowPlaying();

  let playerIcon;
  if (isLoading) {
    playerIcon = (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else if (isPlaying)
    playerIcon = (
      <PauseIcon
        style={{
          backgroundColor: "#ffe400",
          borderRadius: "50%",
          fontSize: "40px",
        }}
      />
    );
  else
    playerIcon = (
      <PlayArrowIcon
        style={{
          backgroundColor: "#ffe400",
          borderRadius: "50%",
          fontSize: "40px",
        }}
      />
    );

  return (
    <>
      <h4>&quot;{currentSong.track}&quot;</h4>
      <h4>{currentSong.artist}</h4>
      <div className="d-flex">
        <div className="flex-grow-1 ms-3">
          <p>
            <b>
              <small>
                on <span className="text-uppercase">{currentSong.show}</span>
              </small>
            </b>
          </p>
        </div>
        <div className="flex-shrink-0 ms-3 align-self-center">
          <span
            onClick={toggleLivestream}
            style={{ cursor: "pointer", color: "black" }}
          >
            {playerIcon}
          </span>
        </div>
      </div>
    </>
  );
};

export default Player;
